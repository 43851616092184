<template>
  <v-row>
    <v-row v-if="!loading">
      <v-row justify="center">
        <!-- <v-dialog v-model="dialog" persistent max-width="500">
          <v-card>
            <v-card-title class="text-h5">
              Delete private app
            </v-card-title>
            <v-card-text>
              Are you sure you want to delete <span class="font-weight-bold">{{ this.app.appName }}</span> ? This will
              revoke all your credentials and may break implementations of your app or storefront. This action can't be
              reversed.
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="" outlined @click="cancel">
                Cancel
              </v-btn>
              <v-btn color="error" depressed @click="deleteApp">
                Delete this private app
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog> -->
      </v-row>
      <v-col cols="12">
        <v-btn class="text-uppercase" color="grey lighten-3" @click="$router.push({ name: 'website.apps-migrate' })">
          <v-icon color="" left>
            mdi-arrow-left
          </v-icon>
          Migrate
        </v-btn>
      </v-col>
      <v-col cols="12">
        <span
          class="page-title display-1 font-weight-bold grey--text text--darken-2"
          v-if="$route.name === 'website.apps-private.edit'"
        >
          {{ app.appName }}
        </span>
        <span class="page-title display-1 font-weight-bold grey--text text--darken-2" v-else>
          {{ $t('Create stores') }}
        </span>
      </v-col>
      <v-col cols="12">
        <v-row>
          <v-col cols="4">
            <div class="font-weight-bold text-subtitle-1">
              <!-- App details -->
            </div>
            <div class="grey--text pt-3">
              <!-- These details can help you keep track of your private apps and stay informed about changes that affect
              your app. -->
            </div>
          </v-col>
          <v-col cols="8">
            <v-card class="mx-auto" elevation="1">
              <v-card-title class="text-subtitle-1 font-weight-bold grey--text text--darken-2 py-0">
                API key
              </v-card-title>
              <v-card-text>
                <v-text-field height="40px" v-model="storeDataTransfer.sourceStoreApiKey"></v-text-field>
              </v-card-text>
              <v-card-title class="text-subtitle-1 font-weight-bold grey--text text--darken-2 py-0">
                Secrect Key
              </v-card-title>
              <v-card-text>
                <v-text-field height="40px" v-model="storeDataTransfer.sourceStoreSecrectKey"></v-text-field>
              </v-card-text>
              <v-card-title class="text-subtitle-1 font-weight-bold grey--text text--darken-2 py-0">
                URL store
              </v-card-title>
              <v-card-text>
                <v-text-field height="40px" v-model="storeDataTransfer.sourceStoreUrl"></v-text-field>
              </v-card-text>
              <v-card-title class="text-subtitle-1 font-weight-bold grey--text text--darken-2 py-0">
                Source Store
              </v-card-title>
              <v-card-text>
                <v-select
                  v-model="storeDataTransfer.sourceStoreType"
                  :items="[
                    { id: 'latte', name: 'Dcomcy' },
                    { id: 'shopbase', name: 'Shopbase' },
                    { id: 'shopify', name: 'Shopify' },
                  ]"
                  item-text="name"
                  item-value="id"
                ></v-select>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12">
        <v-divider></v-divider>
      </v-col>
      <v-col cols="12">
        <v-row>
          <v-col cols="12">
            <div class="grey--text pt-3">
              Migrate
            </div>
          </v-col>
          <v-col cols="12">
            <v-card class="mx-auto" elevation="1">
              <template>
                <v-col v-for="(check, k) in storeDataTransfer.importItems" :key="check + k" cols="6" md="6" sm="6">
                  <v-checkbox
                    hide-details
                    :label="`${k.substr(0, k.search(`Checked`))}`"
                    v-model="storeDataTransfer.importItems[k]"
                  ></v-checkbox>
                </v-col>
              </template>
            </v-card>
          </v-col>
        </v-row>
      </v-col>

      <v-col cols="4">
        <v-btn
          color="deep-orange accent-3"
          @click="deletePrivateApp"
          v-if="$route.name === 'website.apps-private.edit'"
        >
          <span class="white--text">Delete this private app</span>
        </v-btn>
      </v-col>
      <v-col cols="8" class="d-flex justify-end">
        <v-btn outlined class="mr-2" @click="$router.push({ name: 'website.apps-private' })">
          <span class="black--text">Cancel</span>
        </v-btn>
        <v-btn color="primary" @click="submit">
          <span class="white--text">Save</span>
        </v-btn>
      </v-col>
    </v-row>
    <v-row v-if="loading">
      <v-col cols="12">
        <v-skeleton-loader class="mx-auto" type="list-item, list-item, list-item, list-item"></v-skeleton-loader>
      </v-col>
    </v-row>
  </v-row>
</template>

<script>
import { migrateApi } from '@/apis/migrate';
export default {
  data() {
    return {
      storeDataTransfer: {
        deleted: false,
        store: localStorage.getItem(`store-id`),
        sourceStoreApiKey: '',
        sourceStoreSecrectKey: '',
        sourceStoreUrl: '',
        sourceStoreType: 1,
        status: 0,
        importItems: {
          themesChecked: false,
          collectionChecked: true,
          navigationChecked: false,
          pagesChecked: false,
          appsChecked: false,
          legalPagesChecked: false,
          shippingCurrencyChecked: false,
          productsChecked: true,
        },
      },
      loading: false,
      dialog: false,
      show: false,
      items: [
        // {
        //   id: 'no-access',
        //   label: 'No access',
        // },
        {
          id: 'read',
          label: 'Read access',
        },
        {
          id: 'write',
          label: 'Write access',
        },
      ],
      scopes: {
        product: 'read',
        order: 'read',
      },
      app: {
        appName: '',
        supportEmail: '',
        scopes: ['READ_PRODUCT', 'READ_ORDER'],
      },
      type: 'text',
      emailRules: [
        () => !!this.app.supportEmail || 'E-mail is required',
        () => /.+@.+\..+/.test(this.app.supportEmail) || 'E-mail must be valid',
      ],
    };
  },
  methods: {
    async submit() {
      if (this.$route.name === 'website.apps-migrate.create') {
        try {
          let app = await migrateApi.create(this.storeDataTransfer);
          // window.location.href = `/admin/app/migrate`;
          this.$router.push({ name: 'website.apps-migrate.edit', params: { id: app.data.storeDataTransfer._id } });
        } catch (e) {
          console.log('error', e);
        }
      } else if (this.$route.name === 'website.apps-migrate.edit') {
        await migrateApi.update(this.storeDataTransfer);
        this.loading = true;
        try {
          let app = await migrateApi.getById(this.$route.params.id);
          this.app = app.data.storeDataTransfer;
        } catch (error) {
          console.log('error', error);
        }
      }
      this.loading = false;
    },
    copy(ref) {
      let copy = this.$refs[ref];
      copy.select();
      navigator.clipboard.writeText(copy.value);
    },
    setType() {
      if (this.type === 'text') {
        this.type = 'password';
      } else if (this.type === 'password') {
        this.type = 'text';
      }
    },
    deletePrivateApp() {
      this.dialog = true;
    },
    async deleteApp() {
      try {
        await migrateApi.deletePrivateApp(this.app._id);
        this.$store.commit('setMessages', {
          messages: 'Delete app successfully!',
          type: 'success',
        });
        this.$router.push({ name: 'website.apps-private' });
      } catch (error) {
        this.$store.commit('setMessages', {
          messages: 'Error',
          type: 'error',
        });
      }
      this.dialog = false;
    },
    cancel() {
      this.dialog = false;
    },
  },
  watch: {},
  async created() {
    if (this.$route.name === 'website.apps-migrate.edit') {
      this.loading = true;
      try {
        let app = await migrateApi.getById(this.$route.params.id);
        this.storeDataTransfer = app.data.storeDataTransfer;
      } catch (error) {
        console.log('error', error);
      }
      this.loading = false;
    } else if (this.$route.name === 'website.apps-migrate.create') {
      this.loading = false;
    }
  },
};
</script>

<style lang="scss">
.fs-13 {
  size: 13px;
}
.v-select__selections {
  input {
    display: none;
  }
}
.private-app {
  color: #0091eb;
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}
.info-input {
  height: 40px;
  width: 100%;
  padding: 0px 10px;
  border: 1px solid grey;
  border-radius: 5px;
}
.password {
  position: relative;
  .icon-password {
    cursor: pointer;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 15%;
    color: #0091eb;
  }
}
</style>
